import React, { Component } from 'react';
import Checkedd from './components/checkbox';
import Addhomework from './popups/addhomework';
import Times from './popups/times';
import studentService from '../services/studentService';
import fire from '../assets/fire.png';
import Checkedd3 from './components/checkbox3';
import ReportService from '../services/reportService';
import calendarService from '../services/calendarService';
import ParentFormComponent from '../npm/parentFormComponent';
class Homework extends Component {
    constructor(props) {
        //create state
        super(props);
        this.state = {
            homeworks: undefined,
            archive: false
        };
    }

    async componentDidMount(){
        
        let app = this.props.app;
        let state = app.state;
        let dispatch = app.dispatch;
        let componentList = this.props.app.state.componentList;
        let currentReport = await ReportService.createCurrentReport(componentList, state.currentstudent?.getJson()._id).then(async (currentReport)=>{
            await dispatch({currentReport:currentReport, review:false});
            ReportService.getAllReportsFromNow(componentList, state.currentstudent?.getJson()._id)
        });
        


    }


    render() {
        let app = this.props.app;
        let state = app.state;
        let styles = state.styles;
        let dispatch = app.dispatch;
        let factory = state.factory;
        let comp = this.props.app.state.componentList;
        let id = state.currentstudent.getJson()._id
        let bool = state.popupSwitch === "addhomework" || state.popupSwitch === "updateHomework";

        return (
            <div style={
                {
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    
                }
            }>
                <div style={
                    {...styles.biggercard,
                         width:state.ipad&&state.currentuser?.getJson().role==="student"? state.iphone?"95vw":"90vw":state.iphone?"95vw":state.ipad?"62vw":"50vw",
                          marginLeft:"0px", position:"relative"}

                } >
                    {state.currentuser.getJson().role==="teacher"&&
<div onClick={()=>{this.setState({archive:!this.state.archive})}} style={{...styles.buttons.buttonExpand, bottom:"5px", left:"10px", position:"absolute"}}>{this.state.archive?<>Back</>:<>See Archive...</>}</div>}
                    {bool && (<Addhomework app={app} handleClose={()=>{
                        dispatch({ popupSwitch: "" });
                        state.componentList.getOperationsFactory().clearUpdater();
                        }} />)}
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: styles.fonts.fontweightMed,
                        padding: styles.margins.margin4,

                        background: styles.colors.colorLink
                        //+ "88"
                        ,
                        borderRadius: "23px 23px 0px 0px",
                        fontSize: styles.fonts.fontsizeTitle,
                        fontFamily: styles.fonts.appFont,
                        fontWeight: styles.fonts.fontweightMed,



                    }}>
                        
                        <div
                            style={{
                                marginLeft: styles.mystudents.studentMargin,
                                color: this.props.app.state.styles.colors.color6,
                                letterSpacing: styles.fonts.appSpacing,

                            }}
                        >{this.state.archive? <>Archive</>:<>Practice</>}</div>
                        {this.props.app.state.currentuser.getJson().role === "student" ? (<></>) : (<div
                            onClick={() => { dispatch({ popupSwitch: "addhomework", operate: 'addhomework',operation:"cleanJsonPrepare", object: { owner: id } }) }}
                            style={

                                {...styles.buttons.buttonAdd,
                                    marginRight: ".83vw",
                                    width:state.iphone? "13vw":"6.5vw",
                                    fontSize:state.iphone?"12px":".79vw"
                            }
                            }
                        >{this.state.archive?<></>:<>+ Add Assignment</>}</div>)}

                    </div>
                    {!this.state.archive?(
                    <div style={{justifyContent:state.ipad&&"space-between", ...styles.alignMain, marginTop:" 30px", }}>
                        {!state.iphone&&(
                        <div style={{height: state.ipad? "28vh":window.innerHeight<800?"30vh":"33vh", width:state.ipad?"65vw": "auto"}}>
                            <div className="scroller">
                            {comp.getList("homework", id).map((homework, index) =>
                                <div style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    marginBottom:"10px",
                                    marginLeft:"20px",
                                    justifyContent: "space-between",
                                    flexDirection: "column",
                                    
                                }}>
                                    <div style={{
                                        font: styles.fonts.fontEdit,
                                        fontFamily: styles.fonts.appTitle,
                                        fontSize: styles.fonts.fontsize1,
                                        fontWeight: styles.fonts.fontweightMain,
                                        display: "flex",
                                        // justifyContent: "space-between",
                                        cursor: "pointer",
                                        flexDirection: "row",
                                    }}key={index}>
                                        <Checkedd3   size= {styles.checkbox.size1} homework={homework} app={app} />
                                     <div style={{marginLeft: styles.margins.margin4, flexDirection:'row', display:"flex", cursor: "pointer",
                                        }}
                                            onClick={ async ()=>{
                                                if(state.currentuser.getJson().role==="teacher"){
                                                    dispatch({ popupSwitch: "updateHomework", operate: "update", operation: "cleanPrepare", object: homework }, false)
                                                }
                                                else{
                                                    await dispatch({  operate: "update", operation: "cleanPrepare", object: homework })
                                                    const delay = ms => new Promise(res => setTimeout(res, ms));
                                                    await delay(5);
                                                    dispatch({ popupSwitch: "showHomework", })
                                                }
                                                }
                                                }>
                                                <div style={{
                                                    fontSize: styles.fonts.fontsize1,
                                                    fontStyle: "oblique 20deg", cursor: "pointer",
                                                    textDecoration:homework.getJson().done? "line-through": "none", color:homework.getJson().done? "#57BA8E": "black", marginTop:"7px",
                                                }}
                                                >{homework.getJson().title.length>45?(<>{homework.getJson().title.slice(0,45)}...</>):(<>{homework.getJson().title}</>)}</div>
                                        
                                        
                                            <div style={{
                                                marginLeft: styles.margins.margin3, 
                                                fontSize: styles.fonts.fontsize3,
                                                fontWeight: styles.fonts.fontweightMed,
                                                letterSpacing: styles.fonts.appSpacing2,
                                                color:styles.colors.colorLink, 
                                                display:"flex", cursor: "pointer",
                                                flexDirection:"column", 
                                                marginBottom:"7px",
                                                justifyContent:"flex-end"
                                                }}>
                                                    {homework.getJson().title.length>20?(<>{homework.getJson().hwlink.slice(0,20)}...</>):(<>{homework.getJson().hwlink}</>)}</div>
                                        </div>
                                        {/* <div style={{
                                            cursor: "pointer",
                                            marginRight: styles.margins.margin1,
                                            color: styles.colors.colorWarning,
                                            fontWeight: styles.fonts.fontweightMed,
                                            fontSize: styles.fonts.fontsize5,
                                        }}
                                            onClick={dispatch.bind(this, { object: homework, operate: "del", operation: "prepareRun" })}
                                        >delete</div> */}
                                    </div>
                                    <div style={{
                                            marginLeft: styles.margins.margin4,

                                            width:"95%",
                                            color: styles.colors.color3+"a9",
                                            fontWeight: styles.fonts.fontweightMain,
                                        }}>{homework.getJson().description!==""&&(<>Notes: {homework.getJson().description}</>)}
                                        </div>
                                    </div>)}
                        </div></div>)}

                        <div style={{
                            alignContent:"center",  
                           alignItems:"center", textAlign:"center",
                            fontSize: styles.fonts.fontsize1,
                            fontFamily: styles.fonts.appFont,
                            fontWeight: styles.fonts.fontweightMed,
                            marginRight: (state.ipad&&comp.getList("homework", id).length===0)? "0px":"3vw",
                            marginLeft: (state.ipad&&comp.getList("homework", id).length>0)? "2vw": "auto",
                            display: "flex",
                            flexDirection:"column",
                            
                            justifyContent:state.iphone?"center":"",
                            ...styles.iphoneHelp

                        }}>
                            {/* <div style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",

                            }}>


                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    padding: styles.margins.margin4,

                                }}>
                                    <button  className="btn  btn-block"  onClick={dispatch.bind(this, {popupSwitch:"addTime", currentComponent:state.currentstudent})} 

                style={
                    styles.buttons.buttonLog
                }>+ Time</button>
            {state.currentuser.role==="student"?(<></>):(<div>
            <button  className="btn  btn-block"  onClick={state.currentstudent?.clearChecks} style={styles.buttons.buttonClear}>Clear Checks</button>
           
            <button  className="btn  btn-block" onClick={state.currentstudent?.clearTime} style={styles.buttons.buttonClear}>Clear Time</button></div>)} 
                                </div>
                            </div> */}

                            {(state.currentstudent.getJson().check || state.currentstudent.getJson().trackTime)&&(<>
                            <div style={{disply:"flex",flexDirection:"column", marginBottom:"15px", fontSize:"19px", alignContent:"center",  
                                        justifyContent:"center", alignItems:"center", textAlign:"center",}}>Practice Progress {state.currentReport?.getJson().start.slice(0, -5)} - {state.currentReport?.getJson().end.slice(0, -5)}
                            {state.currentstudent.getJson().trackTime&&(<div style={{
                                            cursor:"pointer", 
                                            borderRadius: "2vw", color: "white",
                                        alignItems:"center",  
                                        justifyContent:"center", 
                                        fontSize:"13px", 
                                        marginLeft: state.iphone? "22%":"30%",
                                        background:styles.colors.color1, 
                                        textAlign:"center", 
                                        padding:"2px",
                                        width:"120px" }} onClick={dispatch.bind(this,{popupSwitch: "addTime", currentComponent: state.review? state.currentReport:state.currentstudent,})}>Log Time</div>)}
                            </div>
                            <div style={{width:"100%", display:'flex', flexDirection:'row'}}>
                            {!state.ipad&&<div onClick={async ()=>{
                                
                                let lastWeeksReport = await ReportService.getLastWeeksReport(comp, state.currentstudent?.getJson()._id, state.currentReport);
                                if(lastWeeksReport){
                                    dispatch({currentReport:lastWeeksReport, review:true});

                                }


                            }}
                                       
                                       style={{background:"#00000099", clipPath:"polygon(50% 0, 50% 100%, 100% 50%)", width:"13px", marginTop:"40px", height:"10px",transform:"rotate(180deg)", }}>
                                        </div>}
                                        <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:'center'}}>
                                        {state.ipad&&<div onClick={async ()=>{
                                        
                                let lastWeeksReport = await ReportService.getLastWeeksReport(comp, state.currentstudent?.getJson()._id, state.currentReport);
                                if(lastWeeksReport){
                                    dispatch({currentReport:lastWeeksReport, review:true});

                                }


                            }}
                                       
                                       style={{background:"#00000099", clipPath:"polygon(50% 0, 50% 100%, 100% 50%)", width:"13px", height:"10px",transform:"rotate(180deg)", }}>
                                        </div>}
                            <Checkedd big={true} size={styles.checkbox.size1} app={app} component={state.review? state.currentReport:state.currentstudent}
                                checked={state.review? state.currentReport.getJson().checked:state.currentstudent.getJson().checked} time={state.review?state.currentReport.getJson().time:state.currentstudent.getJson().time} /></div>
                                {state.currentReport?.getJson().start !== calendarService.getMostRecentMonday() &&
                                <div  onClick={async ()=>{
                                    
                                    let lastWeeksReport = await ReportService.getLastWeeksReport(comp, state.currentstudent?.getJson()._id, state.currentReport, true);
                                    if(lastWeeksReport){
                                        await dispatch({currentReport:lastWeeksReport});
                                        
                                        let todaysReport = await ReportService.createCurrentReport(comp, state.currentstudent?.getJson()._id, true);
                                        if(lastWeeksReport.getJson()._id === todaysReport.getJson()._id){
                                            await dispatch({review:false})
                                        }
    
                                    }}
    
    
                                }
                                       
                                       style={{background:"#00000099", clipPath:"polygon(50% 0, 50% 100%, 100% 50%)", width:"13px", height:"10px", marginTop:"40px",marginLeft:"20px", }}></div>}
                                </div>
                                <div style={{width:window.innerWidth<1400&&window.innerWidth>1300? "20vw":styles.sizeSpecStud.width,  height:"fit-content", marginBottom:styles.margins.margin4, display:"flex", flexDirection:"column", alignItems:"center", justifyContent:'center',  marginTop: (state.iphone ||state.ipad)?"0vh":"5vh"}}>
                                    <div style={{display:"flex", flexDirection:"row", alignItems:"center", height:"fit-content"}}><img style={{marginRight:"10px"}} src={fire} alt= "fire"/><div>
                                    <div style={{fontSize:"2vh", fontFamily:styles.fonts.appTitle, marginLeft:"3px"}}>
                                        {state.currentuser?.getJson().type === "student" ?(<>{state.currentstudent.getJson().totalDaysPracticed}</>) :(
                                    <ParentFormComponent app={app} cleanPrepareRun={true} obj={state.currentstudent} name="totalDaysPracticed" inputStyle={{ width: "50px", textAlign: "center" }} />)}Days Practiced</div>
                                    </div>
                                    </div></div></>)}
                        </div>
                        {state.iphone&&(
                        <div style={{height:"42vh", marginTop:"20px", justifyContent:"center", alignItems:"center", display:"flex", flexDirection:"column" }}>
                            <div>Practice Assignments</div>
                            <div className="scroller">
                            {comp.getList("homework", id).map((homework, index) =>
                                <div style={{
                                    display: "flex",
                                    marginBottom:"20px",
                                    marginLeft:"20px",
                                    justifyContent: "space-between",
                                    flexDirection: "column",
                                    
                                }}>
                                    <div style={{
                                        font: styles.fonts.fontEdit,
                                        fontFamily: styles.fonts.appTitle,
                                        fontSize: styles.fonts.fontsize1,
                                        fontWeight: styles.fonts.fontweightMain,
                                        display: "flex",
                                        justifyContent: "space-between",
                                
                                        flexDirection: "row",
                                    }}key={index}>
                                     <div style={{marginLeft: styles.margins.margin4, flexDirection:'row', display:"flex", cursor: state.currentuser.getJson().currentuser==="teacher"? "pointer":"auto",
                                        }}
                                            onClick={async ()=>{
                                                if(state.currentuser.getJson().role==="teacher"){
                                                    dispatch({ popupSwitch: "updateHomework", operate: "update", operation: "cleanPrepare", object: homework }, false)
                                                }
                                                else{
                                                    await dispatch({  operate: "update", operation: "cleanPrepare", object: homework })
                                                    const delay = ms => new Promise(res => setTimeout(res, ms));
                                                    await delay(5);
                                                    dispatch({ popupSwitch: "showHomework", })
                                                }
                                                }}>
                                                <div style={{
                                                    fontSize: styles.fonts.fontsize1,
                                                    fontStyle: "oblique 20deg"
                                                }}
                                                >{homework.getJson().title.length>45?(<>{homework.getJson().title.slice(0,45)}...</>):(<>{homework.getJson().title}</>)}</div>
                                        
                                        
                                            <div style={{
                                                marginLeft: styles.margins.margin3, 
                                                fontSize: styles.fonts.fontsize3,
                                                fontWeight: styles.fonts.fontweightMed,
                                                letterSpacing: styles.fonts.appSpacing2,
                                                color:styles.colors.colorLink, 
                                                display:"flex", 
                                                flexDirection:"column", 
                                                justifyContent:"flex-end"
                                                }}>
                                                    {homework.getJson().title.length>20?(<>{homework.getJson().hwlink.slice(0,20)}...</>):(<>{homework.getJson().hwlink}</>)}</div>
                                        </div>
                                        {/* <div style={{
                                            cursor: "pointer",
                                            marginRight: styles.margins.margin1,
                                            color: styles.colors.colorWarning,
                                            fontWeight: styles.fonts.fontweightMed,
                                            fontSize: styles.fonts.fontsize5,
                                        }}
                                            onClick={dispatch.bind(this, { object: homework, operate: "del", operation: "prepareRun" })}
                                        >delete</div> */}
                                    </div>
                                    <div style={{
                                            marginLeft: styles.margins.margin4,

                                            width:"95%",
                                            color: styles.colors.color3+"a9",
                                            fontWeight: styles.fonts.fontweightMain,
                                        }}>{homework.getJson().description!==""&&(<>Notes: {homework.getJson().description}</>)}
                                        </div>
                                    </div>)}
                        </div></div>)}
                    </div>
                ):(<div style={{padding:"10px"}}>
                        {comp.getList("archive", id).map((archive, index)=>
                        <div style={{display:'flex',flexDirection:"row"}}>
                        <div style={{cursor:"pointer"}} onClick={dispatch.bind(this,{ popupSwitch: "updateHomework", operate: "update", operation: "cleanPrepare", object: archive }, false)}>{archive.getJson().title}</div>
                        <div style={{marginLeft:"20px"}}>compleded on: {archive.getJson().completed}</div>
                        <div style={{marginLeft:"20px", color:"red",cursor:'pointer'}} onClick={()=>{archive.getOperationsFactory().cleanPrepareRun({del:archive})}}>Permanantly Delete</div>
                        </div>
                        )}
                    </div>)}

                    {/* <button style={
                    styles.buttons.buttonLog
                } onClick={()=>{
                    let json = state.currentstudent.getJson()
                    json.weekstreak= parseInt(json.weekstreak)+1;
                    dispatch({object: state.currentstudent, operate:"update", operation:"prepareRun"})}}>award streak</button> */}
                    {/* <button style={
                    styles.buttons.buttonLog
                } onClick={()=>{
                    //
                    let json = state.currentstudent.getJson();
                    json.badges.newBadge= "newbadge"
                    dispatch({object: state.currentstudent, operate:"update", operation:"prepareRun"})}}>award badge</button> */}
                </div>
            </div>

        );
    }
}

export default Homework;