import React, { Component } from "react";
import authService from "../../services/auth.service";
import validator from 'validator';

//details my existingEmail.js component. creates some buttons that use methods embedded in props from the profile page. Choice will update the backend.
class PasswordReset extends Component {
    constructor(props) {
        super(props);

        this.handleChange=this.handleChange.bind(this);
        this.handSub=this.handSub.bind(this);
        this.state = {
            marginTop: "200px",
            type:"password",
            password:"",
            password2:"",
            message:{
                password:"Password must include at least 1 symbol 1 number 1 upper and lower case letters and be at least 8 characters long",
                match:"Passwords must match"

            },
            currentMessage: undefined
            
        }


    }

    componentDidMount() {
        if(this.props.app.state.popupSwitch==="changePassword"){
            document.addEventListener('mousedown', this.handleClickOutside);

        }
    }
    componentWillUnmount() {
        if(this.props.app.state.popupSwitch==="changePassword"){

        document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.handleClose();
        }
    }


    handleChange = (event) => {
        const { name, value } = event.target

        this.setState({
            [name]: value,
        })
    }
    async handSub(){
        if(this.state.password!==this.state.password2){
           await  this.setState({
                currentMessage:this.state.message.match,
            })
            return
        }
        if(validator.isStrongPassword(this.state.password, {
            minLength: 6, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
          })) {
            
          } else {
                await this.setState({
                    currentMessage:this.state.message.password,
                })
                return
            
            
          }
          this.setState({currentMessage:""});
          
          let changePassword = await authService.changePassword(this.state.password);
          if(changePassword){
            let student = this.props.app.state.currentuser;
            await student.setJson({...student.getJson(), adminSignUp:false});
            await this.props.app.state.componentList.getOperationsFactory().cleanPrepareRun({update:student});
            this.props.handleClose();

          }

    }
    render() {
       let app = this.props.app;
       let state = app.state;
       let styles=state.styles;
        return (
            <div className="popup-box to-front">
                <div className="boxdel scroller" ref={this.wrapperRef} style={{marginTop: this.state.marginTop, width:window.innerWidth<600? "95vw":"55vw", height:"50vh"}}>
                {state.popupSwitch==="changePassword"&&<div style={ ///EXIT BUTTON
                                styles.buttons.closeicon
                            } onClick={this.props.handleClose}>x</div>}
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <h1>Please Enter A New Password</h1>
                        <div>
            <div>Password:</div>
            <input  className="form-control" onChange={this.handleChange} name="password" type={this.state.type}/>
            <div>Retype Password:</div>
            <input  className="form-control" onChange={this.handleChange} name="password2" type={this.state.type}/>
            <div style={{color:'blue', cursor:"pointer"}} onClick={()=>{this.setState({type:this.state.type==="password"?"auto":"password"})}}>
                Show/Hide</div>
                </div>

                <button className="btn  btn-block" style={{ background: "#6C86F4", height: "35px", color: "#F0F2EF", width: "85spx", marginTop:"30px" }} onClick={this.handSub} >
                                       Change Password
                                    </button>
                                    {this.state.currentMessage&&(<div style={{color:"red", marginTop:'10px'}}>{this.state.currentMessage}</div>)}
                    </div>
                </div>
            </div>

        )
    }
};

export default PasswordReset;