import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import "./App.css";
import "./view/components/checkbox.css"
import "./index.css";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    //  <React.StrictMode>
        <div style={{
            
        }}>
            <App />
            </div>
            // </React.StrictMode>
);