import React, { Component } from "react";


class Teacher extends Component {
    constructor(props) {
        super(props);
      

        this.state = {
        

    };
}
    
    
   
    render() {


        return (
           <div>My Teacher</div>

        );
    }
}

export default Teacher;